@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill {
    background-color: transparent !important;
}

@font-face {
    font-family: "spaceGrotesk";
    src: local(''),url("/fonts/SpaceGrotesk-VariableFont_wght.ttf");
}
